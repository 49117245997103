
.pdp-additional-product-container {
    padding: 32px 0
    max-width: 1200px

    @media screen and (min-width: 768px) {
        padding: 32px 12px
    }
}

.pdp-additional-product-layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    align-items: flex-start;
    justify-content: center;
    padding: 16px 16px 3.125rem;
    background-color: #f7f7f7;
    border-radius: .5rem;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 16px 16px 3.125rem;
    }

  .pdp-additional-product-item {
    width: 100%;
    flex-basis: 100%;

    @media screen and (min-width: 768px) {
      width: 25%;
      flex-basis: 25%;
    }
  }
}

.pdp-additional-product-action {
    display: flex;
    justify-content: center;
    transform: translateY(-50%);

    .v-btn {
      width: fit-content

      @media screen and (min-width: 768px) {
      width: 100%;
      }
    }
}

.pdp-product-tile-layout {
    display: flex;
    flex-direction: column;
    gap: 60px;
    grid-gap: 60px;
    align-items: flex-start;
    justify-content: center;
    padding: 16px 16px 3.125rem;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 16px 16px 3.125rem;
    }

  .pdp-additional-product-item {
    width: 100%;
    flex-basis: 100%;

    @media screen and (min-width: 768px) {
      // width: 25%;
      // flex-basis: 25%;
    }
  }
}

.v-btn.swiper-product-button{
    transition: all .15s ease;
    font-size: 1.375rem;
    height: 3rem !important;
    width: 3rem !important;
    margin-top: 0 !important;
    transform: translateY(-50%);
    border-radius: 50% !important;
    background-color: #0071e3 !important;
    color: #fff !important;
    padding: 0 !important;
    min-width: 3rem !important;

    position absolute
    top 34%

    z-index 10
}
.swiper-slide-container{
    max-width 996px
    padding 8px 2px 26px

    @media screen and (min-width: 768px) {
        padding 0
    }
}
.product-swiper-container {
    position: relative;
    width: 100%;
    padding: 0

    @media screen and (min-width: 1024px) {
        padding: 0 64px
    }

    .swiper-product-button-prev {
      left: -6px;
      display: none;
        @media screen and (min-width: 1024px) {
            display: block;
        }
    }

    .swiper-product-button-next {
        right: -6px;
        display: none;

        @media screen and (min-width: 1024px) {
            display: block;
        }
    }
}

.swiper-pagination {
    position: static !important;
    display: flex;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 2rem;
    padding: .5rem 0;
}

::v-deep {
    .swiper-slide {
        width: fit-content !important;
    }

    .swiper-pagination-bullet {
        width: 12px !important;
        height: 12px !important;
        opacity: 1 !important;
        background-color: #d0d0d0 !important;
        border-radius: 50% !important;
        transition: width .3s, background-color .3s;
    }

    .swiper-pagination-bullet-active {
        background-color: #969696 !important
    }
}
